<div class="slider_area">
    <div class="single_slider  d-flex align-items-center slider_bg_demo">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-xl-8">
                    <div class="slider_text text-center justify-content-center">
                        <h3>Demo</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <h3><strong>Investor and Administrator Platform Demo</strong></h3><br>
                <p>
                    The <em><strong>7G Registry Platform</strong></em> is a white-label product. This means that your investor portal will be branded to look like your website and other branding initiatives. To demonstrate what the system can do we have
                    provided two links below. The first one is for your investors where they can register and see their holdings, update their details etc. The second link takes you to the administrator user interface in which you do all the back office
                    work for your financial products. For the demo we have created a SevenG brand. In reality there is no SevenG registry provider, we are the technology provider, but to demonstrate our product we have created a SevenG brand.
                </p>
                <p>Click on the links below to access the <em><strong>Investor Portal</strong></em> or <em><strong>Administrator Platform</strong></em></p>
                <p>Please contact a <em><strong>SevenG</strong></em> representative to help guide you through our intuitive software.</p>
                <div style="height:20px"></div>
                <div class="row">
                    <div class="col-md-6"><a href="https://demo-seveng-portal.7g.com.au/" target="_blank" rel="noopener noreferrer">CLICK HERE FOR THE INVESTOR PORTAL</a>
                        <figure class="wp-block-image size-large is-resized">
                            <a href="https://demo-seveng-portal.7g.com.au/" target="_blank" rel="noreferrer noopener"><img src="../../assets/img/Screen-shot-InvestorUI-400x261.png"></a>
                        </figure>
                    </div>
                    <div class="col-md-6"><a href="https://demo.7g.com.au" target="_blank" rel="noopener noreferrer">CLICK HERE FOR THE ADMIN PLATFORM</a>
                        <figure class="wp-block-image size-large is-resized">
                            <a href="https://demo.7g.com.au" target="_blank" rel="noreferrer noopener"><img src="../../assets/img/Screen-shot-AdminUI-400x261.png"></a>
                        </figure>
                    </div>
                </div>
            </div>
            <aside class="col-md-3">
                <aside>
                    <div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/ZenBook-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/ZenBook-1-300x180.png 300w, ../../assets/img/ZenBook-1.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownRight animated"><img width="300" height="152" src="../../assets/img/Ipad-Pro-2-300x152.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Ipad-Pro-2-300x152.png 300w, ../../assets/img/Ipad-Pro-2-300x152.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/Pixel-4XL-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Pixel-4XL-1-300x180.png 300w, ../../assets/img/Pixel-4XL-1.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                    </div>
                </aside>
            </aside>
        </div>
    </div>