<div class="slider_area">
    <div class="single_slider  d-flex align-items-center slider_bg_contact">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-xl-8">
                    <div class="slider_text text-center justify-content-center">
                        <h3>Thank You!</h3>
                        <p class="thanks-shadow-text">Thanks for getting in contact with us.</p>
                        <p class="thanks-shadow-text">A member of the team will contact you shortly.</p>
                        <a class="thanks-shadow-text thanks-hover"href="/home">Return to Home</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>