<!-- contact_location  -->
<div class="contact_location">
    <div>
        <div class="logo">
            <a routerLink="/home">
                <img src="../assets/img/7g.png">
            </a>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-xl-4 col-md-4">
                <div class="location_left">
                    <h3> <img src="../assets/img/location.svg"> Get in touch</h3>
                    <ul>
                        <li>
                            <a href="https://www.linkedin.com/company/seveng-pty-ltd/" target="blank"> <i class="fa fa-linkedin"></i> </a>
                        </li>
                        <li>
                            <a href="https://web.whatsapp.com/adamwright" target="blank"> <i class="fa fa-whatsapp"></i> </a>
                        </li>
                    </ul><br>
                </div>
            </div>
            <div class="col-xl-4 col-md-4">
                <div class="single_location">
                    <h3> <img src="../assets/img/location.svg"> Mailing Address</h3>
                    <p>SevenG<br>GPO Box 1548<br>Brisbane QLD 4001</p><br>
                </div>
            </div>
            <div class="col-xl-4 col-md-4">
                <div class="single_location">
                    <h3> <img src="../assets/img/headset.svg"> Reach Us</h3>
                    <div>
                        <ul>
                            <li>
                                <a href="mailto:sales@7g.com.au"> <i class="fa fa-envelope"></i> sales@7g.com.au</a>
                            </li><br>
                            <li>
                                <a href="mailto:support@7g.com.au"> <i class="fa fa-envelope"></i> support@7g.com.au</a>
                            </li><br>
                            <li>
                                <a> <i class="fa fa-phone"> </i> +61 422 091 443</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <span><br><br>© Copyright 2021 All Rights Reserved.</span>
        </div>
    </div>
</div>
<!--/ contact_location  -->