import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onlineapplications',
  templateUrl: './onlineapplications.component.html',
  styleUrls: ['./onlineapplications.component.css']
})
export class OnlineapplicationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
