import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  contactForm: UntypedFormGroup;

  siteKey?: string;
  secretKey?: string;
  isValid: boolean = true;

  constructor() {
    this.contactForm = new UntypedFormGroup({
      recaptcha: new UntypedFormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.siteKey = '6LfIx6oUAAAAAHhHyyZVzBTqR_pVsVtwrMzhA4Lb';
    this.secretKey = '6LfIx6oUAAAAADFUzEOggF6mKokjiL1hPQArZ6IY';
  }

  public handleSuccess = (data: any) => {
    this.isValid = false;
  }

  public handleReset = () => {
  }

  public handleExpire = () => {
  }

  public handleLoad = () => {
  }

}
