<div class="slider_area">
  <div class="single_slider  d-flex align-items-center slider_bg_home">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-xl-8">
          <div class="slider_text text-center justify-content-center">
            <h3>Welcome to SevenG</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<hr>
<div class="container">
  <div class="row">
    <div class="col-md-9">
      <h2><strong>Registry Platform Software Solution </strong></h2>
      <h4><strong>For</strong><strong> unlisted and listed Funds Management</strong></h4>
      <p><strong><em>Since 2001 SevenG has been helping businesses take control of registry management. </em></strong>
      </p>
      <ul class="col-md-12">
        <li>• Flexibility and control</li>
        <li>• Tailored solutions to manage costs</li>
        <li>• Saving time and improving productivity</li>
        <li>• IT knowledge and expertise not required</li>
        <li>• Addressing all reporting requirements for ATO, CRS/FATCA, AML/CTF, and KYC</li>
        <li>• Full support for MIT, AMIT, MFUND, ETF, LIT, LIC among other product types</li>
      </ul><br>
      <p>Our software is a registry platform that handles all types of managed funds whether they be unlisted or listed.
        It is also a fully-fledged Share Registry platform that handles all common corporate actions. </p>
      <p>All types of distributions are supported, including all the reporting that entails, both to investors and the
        ATO. All necessary calculations for distribution/tax components for MIT and AMIT are supported in regards to
        periodic, distribution
        and annual reporting (Including SDS and AMMA statements and the AIIR report).</p>
      <p>Any kind of fee structure is supported, such as management fees or adviser service fees. These are set up,
        either simply report based or required to generate actual monetary or unit-based transactions. Detailed
        reporting options are included
        for dealer groups, advisers, fund managers and investors alike.&nbsp;</p>
      <p>The Registry Platform is connected to the ASX’s CHESS system. For listed products such as Exchange Traded Funds
        (ETF), Listed Investment Trusts (LIT), Listed Investment Companies (LIC) there is full automation. There is also
        support for ASX
        quoted unlisted funds like MFunds.</p>
      <hr>
      <h4><em><strong>What our Customers are saying…</strong></em></h4>
      <h5><strong>Diversified Income Fund</strong></h5>
      <blockquote>
        <p><em>We have been using the registry software provided by SevenG for around 12 years. During this time, we
            have been impressed by the ease in which we have been able to conduct our two managed investment schemes
            that we have operated.</em></p>
      </blockquote>
      <h5><strong>Lifesettlements Fund</strong></h5>
      <blockquote>
        <p><em>Lifesettlements is a world-wide investment fund with more than $1b under management. Our backend and
            administration systems are of vital importance for our business. SevenG has been a provider to us for over
            15 years and their technologies give us a great advantage for client and channel communications</em>.</p>
      </blockquote>
      <p></p>
    </div>
    <aside class="col-md-3">
      <aside>
        <div>
          <div class="rotateInDownLeft animated"><img width="300" height="180"
              src="../../assets/img/ZenBook-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/ZenBook-1-300x180.png 300w, ../../assets/img/ZenBook-1.png 499w"
              sizes="(max-width: 300px) 100vw, 300px"></div>
          <div class="rotateInDownRight animated"><img width="300" height="152"
              src="../../assets/img/Ipad-Pro-2-300x152.png" style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/Ipad-Pro-2-300x152.png 300w, ../../assets/img/Ipad-Pro-2.png 499w"
              sizes="(max-width: 300px) 100vw, 300px"></div>
          <div class="rotateInDownLeft animated"><img width="300" height="180"
              src="../../assets/img/Pixel-4XL-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/Pixel-4XL-1-300x180.png 300w, ../../assets/img/Pixel-4XL-1.png 499w"
              sizes="(max-width: 300px) 100vw, 300px"></div>
          <div class="rotateInDownRight animated"><img width="300" height="180"
              src="../../assets/img/PixelBook-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/PixelBook-300x180.png 300w, ../../assets/img/PixelBook.png 499w"
              sizes="(max-width: 300px) 100vw, 300px"></div>
          <div class="rotateInDownLeft animated"><img width="300" height="180"
              src="../../assets/img/Mac-Pro-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/Mac-Pro-1-300x180.png 300w, ../../assets/img/Mac-Pro-1.png 499w"
              sizes="(max-width: 300px) 100vw, 300px"></div>
          <div class="rotateInDownRight animated"><img width="300" height="180"
              src="../../assets/img/ZenBook-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/ZenBook-1-300x180.png 300w, ../../assets/img/ZenBook-1.png 499w"
              sizes="(max-width: 300px) 100vw, 300px"></div>
        </div>
      </aside>
    </aside>
  </div>
</div>
