<div class="slider_area">
    <div class="single_slider  d-flex align-items-center slider_bg_oip">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-xl-8">
                    <div class="slider_text text-center justify-content-center">
                        <h3>Online Investor Portal</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <h2><strong>Online Investor Portal</strong></h2>
                <div style="height:20px"></div>
                <p><strong><em>The 7G Online Investor Portal</em></strong> is a module of the 7G platform that allows investors access to their investment accounts on 7G.&nbsp;</p>
                <div>
                    <figure><img src="../../assets/img/InvestorPortal_AccountB-768x445.png" srcset="../../assets/img/InvestorPortal_AccountB-768x445.png 1024w, ../../assets/img/InvestorPortal_AccountB-300x174.png 300w, ../../assets/img/InvestorPortal_AccountB-768x445.png 768w"
                            sizes="(max-width: 1024px) 100vw, 1024px"></figure>
                </div>
                <div style="height:20px"></div>
                <p>A modern web-application allows the investor to create a user account on the system. Using that account, they can see and update their details, see their transaction history and all their statements.</p>
                <p><strong>The result is the convenience to the investor while saving much work for the registry provider.</strong></p>
                <div style="height:31px"></div>
            </div>
            <aside class="col-md-3">
                <aside>
                    <div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/ZenBook-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/ZenBook-1-300x180.png 300w, ../../assets/img/ZenBook-1.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownRight animated"><img width="300" height="152" src="../../assets/img/Ipad-Pro-2-300x152.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Ipad-Pro-2-300x152.png 300w, ../../assets/img/Ipad-Pro-2-300x152.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/Pixel-4XL-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Pixel-4XL-1-300x180.png 300w, ../../assets/img/Pixel-4XL-1.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownRight animated"><img width="300" height="180" src="../../assets/img/PixelBook-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/PixelBook-300x180.png 300w, ../../assets/img/PixelBook.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                    </div>
                </aside>
            </aside>
        </div>
    </div>