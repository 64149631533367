import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registryplatform',
  templateUrl: './registryplatform.component.html',
  styleUrls: ['./registryplatform.component.css']
})
export class RegistryplatformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
