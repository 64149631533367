import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { ContactusComponent } from './contactus/contactus.component';
import { DemoComponent } from './demo/demo.component';
import { HomeComponent } from './home/home.component';
import { OnlineapplicationsComponent } from './onlineapplications/onlineapplications.component';
import { OnlineinvestorportalComponent } from './onlineinvestorportal/onlineinvestorportal.component';
import { RegistryplatformComponent } from './registryplatform/registryplatform.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { ThanksComponent } from './thanks/thanks.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '/', redirectTo: '/home', pathMatch: 'full' },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'compliance', component: ComplianceComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'demo', component: DemoComponent },
  { path: 'home', component: HomeComponent },
  { path: 'onlineapplications', component: OnlineapplicationsComponent },
  { path: 'onlineinvestorportal', component: OnlineinvestorportalComponent },
  { path: 'registryplatform', component: RegistryplatformComponent },
  { path: 'solutions', component: SolutionsComponent },
  { path: 'testimonials', component: TestimonialsComponent },
  { path: 'thanks', component: ThanksComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }