<div class="slider_area">
    <div class="single_slider  d-flex align-items-center slider_bg_oa">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-xl-8">
                    <div class="slider_text text-center justify-content-center">
                        <h3>Online Applications</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <h2><strong>Online Applications</strong></h2>
                <p><strong><em>7G Online Applications</em>&nbsp;</strong>is a module of the 7G platform that allows investors to enter their applications into the system, rather than sending in paper-based forms. The result leads to immediate great time-savings
                    for investors, advisers, fund managers/administrators alike.</p>
                <p>A modern web-application allows the investor to create a user account on the system. Using that account, they can apply for units in any investment product under your management. The online processing helps the investor fill all this out,
                    including identity checks for Know-Your-Client (KYC) and AML/CTF compliance.</p>
                <p>This will also allow the investor to have continued access to its data to update its’ details or request statements rather than contacting the registry provider. This also allows the investor to reuse its’ investment entity details to
                    top-up investments or invest in other investment products in your portfolio, without having to re-enter all their details.</p>
                <div style="height:20px"></div>
                <figure><img src="../../assets/img/OnlineApp-768x444.png" alt="" class="wp-image-547" srcset="../../assets/img/OnlineApp-768x444.png 1024w, ../../assets/img/OnlineApp-300x173.png 300w, ../../assets/img/OnlineApp-768x444.png 768w" sizes="(max-width: 1024px) 100vw, 1024px"></figure>
            </div>
            <aside class="col-md-3">
                <aside>
                    <div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/ZenBook-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/ZenBook-1-300x180.png 300w, ../../assets/img/ZenBook-1.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownRight animated"><img width="300" height="152" src="../../assets/img/Ipad-Pro-2-300x152.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Ipad-Pro-2-300x152.png 300w, ../../assets/img/Ipad-Pro-2-300x152.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/Pixel-4XL-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Pixel-4XL-1-300x180.png 300w, ../../assets/img/Pixel-4XL-1.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownRight animated"><img width="300" height="180" src="../../assets/img/PixelBook-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/PixelBook-300x180.png 300w, ../../assets/img/PixelBook.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/Mac-Pro-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Mac-Pro-1-300x180.png 300w, ../../assets/img/Mac-Pro-1.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                    </div>
                </aside>
            </aside>
        </div>
    </div>