<div class="slider_area">
    <div class="single_slider  d-flex align-items-center slider_bg_about">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-xl-8">
                    <div class="slider_text text-center justify-content-center">
                        <h3>About Us</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <h3><strong>Meet the team</strong></h3>
                <div style="height:20px"></div>
                <p><strong><em>Founded in 2001, SevenG has been helping businesses take control of managing registries for two decades. </em></strong></p>
                <p>Our team are highly experienced and have the capability to help your company put the systems in place to grow.</p>
                <p>SevenG is a registry platform that meets the needs of all types of managed funds, whether they be unlisted or listed, while also being a fully-fledged Share Registry platform that handles all common corporate actions.</p>
                <p>SevenG is your partner in Registry Solutions.</p>
                <h3><strong>Robert Eliasson – Managing Director</strong></h3>
                <div>
                    <figure><img src="../../assets/img/robertBWSB.png" sizes=" (max-width: 232px) 100vw, 232px "></figure>
                    <div>
                        <p>Robert has nearly 20 years’ experience in Funds Management and Share Registry technologies in Australia. He also has a solid technical background within the financial technology environment, he was a director and co-owner of a
                            Swedish company delivering IT solutions for some of the largest financial institutions in Sweden.&nbsp;</p>
                    </div>
                </div>
                <div style="height:20px "></div>
                <h3><strong>Software Developers</strong></h3>
                <p>SevenG is always enhancing our solutions for our clients. Due to this SevenG employs leading-edge developers to ensure the platforms are consistently updated and optimised. </p>
            </div>
            <aside class="col-md-3 ">
                <aside>
                    <div>
                        <div class="rotateInDownLeft animated "><img width="300 " height="180 " src="../../assets/img/ZenBook-1-300x180.png " style="max-width: 100%; height: auto; margin-bottom: 30px; " srcset="../../assets/img/ZenBook-1-300x180.png 300w,
    ../../assets/img/ZenBook-1.png 499w " sizes="(max-width: 300px) 100vw, 300px "></div>
                        <div class="rotateInDownRight animated "><img width="300 " height="152 " src="../../assets/img/Ipad-Pro-2-300x152.png " style="max-width: 100%; height: auto; margin-bottom: 30px; " srcset="../../assets/img/Ipad-Pro-2-300x152.png
    300w, ../../assets/img/Ipad-Pro-2-300x152.png 499w " sizes="(max-width: 300px) 100vw, 300px "></div>
                        <div class="rotateInDownLeft animated "><img width="300 " height="180 " src="../../assets/img/Pixel-4XL-1-300x180.png " style="max-width: 100%; height: auto; margin-bottom: 30px; " srcset="../../assets/img/Pixel-4XL-1-300x180.png
    300w, ../../assets/img/Pixel-4XL-1.png 499w " sizes="(max-width: 300px) 100vw, 300px "></div>
                        <div class="rotateInDownRight animated "><img width="300 " height="180 " src="../../assets/img/PixelBook-300x180.png " style="max-width: 100%; height: auto; margin-bottom: 30px; " srcset="../../assets/img/PixelBook-300x180.png 300w,
    ../../assets/img/PixelBook.png 499w " sizes="(max-width: 300px) 100vw, 300px "></div>
                        <div class="rotateInDownLeft animated "><img width="300 " height="180 " src="../../assets/img/Mac-Pro-1-300x180.png " style="max-width: 100%; height: auto; margin-bottom: 30px; " srcset="../../assets/img/Mac-Pro-1-300x180.png 300w,
    ../../assets/img/Mac-Pro-1.png 499w " sizes="(max-width: 300px) 100vw, 300px "></div>
                    </div>
                </aside>
            </aside>
        </div>
    </div>