<!-- slider_area_start -->
<div class="slider_area">
  <div class="single_slider  d-flex align-items-center slider_bg_compliance">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-xl-8">
          <div class="slider_text text-center justify-content-center">
            <h3>Compliance</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<hr>
<!-- slider_area_end -->

<div class="container">
  <div class="row">
    <!-- Blog Area -->
    <div class="col-md-9">
      <h2><strong>Compliance</strong></h2>
      <p><strong><em>One of the most important reasons for selecting a trusted registry platform provider is compliance.
          </em></strong></p>
      <p>7G helps your registry or fund take control of your compliance requirements. Every year there are changes to
        legislative requirements for managing a registry or fund. 7G’s team of experts spend the time ensuring our
        platform meets all the
        changes, ensuring your registry or fund is compliant with the relevant governing bodies. </p>
      <h4><strong>CRS/FATCA</strong></h4>
      <p>Common reporting system between OECD companies </p>
      <h4><strong>Australian Tax Office</strong></h4>
      <p>Helping with ATO compliance by providing annual reports as part of your subscription.</p>
      <h4><strong>AML / CTF</strong></h4>
      <p>Anti-Money Laundering and Counter-Terrorism Financing Act 2006 (AML/CTF)</p>
      <p>AML/CTF puts a significant responsibility on&nbsp;Reporting entities&nbsp;to ensure the Act is adhered to. Not
        only does a Reporting&nbsp;entity need to submit a compliance report every year but it also has tools in place
        to monitor and track
        all activities as&nbsp;specified in the compliance report.</p>
      <p>Please note that the Act is based on:</p>
      <blockquote>
        <p><em>“AML/CTF programs are risk-based. This means reporting entities can develop their own programs with
            minimal cost,&nbsp;tailored to their situation and money laundering and terrorism financing risks. This
            approach recognises that the reporting entity is in the best position to assess the risk of their customers,
            products, and services and to allocate resources to counter those risks. The risk-based approach also
            ensures there is minimum impact on customers.”</em></p>
      </blockquote>
      <p>In essence, a compliance plan from one Reporting entity may not cover the needs of another and vice versa.</p>
      <p><strong>7G provides multiple tools to simplify this process:</strong></p>
      <ul class="col-md-12">
        <li>• Training registers to ensure all employees are up-to-date with AML/CTF requirements</li>
        <li>• Ongoing client due diligence</li>
        <li>• Methods of identifying customers</li>
        <li>• Ensuring minimum ‘know your client’ (KYC) information</li>
        <li>• Transaction monitoring program</li>
        <li>• Enhanced customer due to diligence program for customers at high risk</li>
        <li>• Continuous matching of customers to FINCEN and AUSTRAC blacklists</li>
      </ul><br>
      <p>All of these features will ensure minimum overhead of the AML/CTF on your business while ensuring you are
        complying&nbsp;fully with your compliance plan and the Act.</p>
    </div>
    <!--Sidebar Area-->
    <aside class="col-md-3">
      <aside>
        <div>
          <div class="rotateInDownLeft animated">
            <img width="300" height="180" src="../../assets/img/ZenBook-1-300x180.png"
              style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/ZenBook-1-300x180.png 300w, ../../assets/img/ZenBook-1.png 499w"
              sizes="(max-width: 300px) 100vw, 300px">
          </div>
          <div class="rotateInDownRight animated">
            <img width="300" height="152" src="../../assets/img/Ipad-Pro-2-300x152.png"
              style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/Ipad-Pro-2-300x152.png 300w, ../../assets/img/Ipad-Pro-2-300x152.png 499w"
              sizes="(max-width: 300px) 100vw, 300px">
          </div>
          <div class="rotateInDownLeft animated">
            <img width="300" height="180" src="../../assets/img/Pixel-4XL-1-300x180.png"
              style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/Pixel-4XL-1-300x180.png 300w, ../../assets/img/Pixel-4XL-1.png 499w"
              sizes="(max-width: 300px) 100vw, 300px">
          </div>
          <div class="rotateInDownRight animated">
            <img width="300" height="180" src="../../assets/img/PixelBook-300x180.png"
              style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/PixelBook-300x180.png 300w, ../../assets/img/PixelBook.png 499w"
              sizes="(max-width: 300px) 100vw, 300px">
          </div>
          <div class="rotateInDownLeft animated">
            <img width="300" height="180" src="../../assets/img/Mac-Pro-1-300x180.png"
              style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/Mac-Pro-1-300x180.png 300w, ../../assets/img/Mac-Pro-1.png 499w"
              sizes="(max-width: 300px) 100vw, 300px">
          </div>
          <div class="rotateInDownRight animated">
            <img width="300" height="180" src="../../assets/img/ZenBook-1-300x180.png"
              style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/ZenBook-1-300x180.png 300w, ../../assets/img/ZenBook-1.png 499w"
              sizes="(max-width: 300px) 100vw, 300px">
          </div>
        </div>
      </aside>
    </aside>
    <!--Sidebar Area-->
  </div>
</div>
