<div class="slider_area">
    <div class="single_slider  d-flex align-items-center slider_bg_solutions">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-xl-8">
                    <div class="slider_text text-center justify-content-center">
                        <h3>Solutions</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <h2><strong>Registry Platform</strong></h2>
                <p>The <strong><em>7G Registry Platform</em></strong> has functionality to cater for a wide range of financial products. On the unlisted side it can handle all types of funds that have been presented to it over the past nearly two decades.
                    These days they are normally categorised, reporting-wise, into the main categories of Managed Investment Trusts <em><strong>(MIT)</strong></em> and Attributed Managed Investment Trusts <em><strong>(AMIT)</strong></em>.

                </p>
                <p>On the listed side our platform is connected to the ASX via CHESS. It caters for Listed Investment Companies <em><strong>(LIC)</strong></em>, Listed Investment Trusts <em><strong>(LIT)</strong></em>, Exchange Traded Funds <em><strong>(ETF)</strong></em>                    and Exchange Traded Managed Funds <em><strong>(ETMF)</strong></em>. Finally it also provides functionality for <em><strong>(MFunds)</strong></em>. </p>
                <p>The <strong><em>Typical Clients</em></strong> are:</p>
                <h4><strong>Fund Managers</strong></h4>
                <p><strong><em>Registry for fund managers</em></strong> traditionally has two choices, either Inhouse or Outsourcing. With SevenG we support you whichever way you choose to operate.</p>
                <h6><strong>In-house</strong></h6>
                <p>Traditionally managing registry inhouse for a fund manager meant expensive investments, developing systems and maintaining those systems inhouse.</p>
                <p>
                    Using the&nbsp;<em>7G Registry Platform</em>, you immediately benefit from a fully-fledged registry platform that can handle anything a managed fund will need to do and more. You also benefit from no technical investment or maintenance.
                    Since the&nbsp;<em>7G Registry platform</em>&nbsp;is hosted in SevenG’s datacentres, SevenG will manage all technical issues around data storage, uptime, backups redundancy and more allowing you the time to focus on what you do best,
                    managing your Funds.&nbsp;
                </p>
                <h6><strong>Outsourcing</strong></h6>
                <p>
                    Outsourcing it to a fund administrator frees up internal resources for the core business. This is a convenient choice, but it can lead to a bit of lost control and insight into your registry data. It may be less of an issue if your fund administrator
                    uses a platform like the&nbsp;<em>7G Registry Platform</em>&nbsp;since, if agreed, they can then give you a different type of access to your data.
                </p>
                <h4><strong>Fund Administrators</strong></h4>
                <p>For Fund Administrators, there are also only two options. With SevenG, we support how you want to manage your funds. </p>
                <h6><strong>In-house Technology Solution</strong></h6>
                <p>
                    If a fund administrator that handles funds for multiple fund managers wants to develop their inhouse registry platform, they face many expensive challenges. <br>A modern registry platform is a comprehensive system, requiring significant
                    resource to develop, and years of experience to learn all functionality necessary to support all types of funds with the reporting requirements that it entails.
                </p>
                <h6><strong>Outsourced Technology Solution</strong></h6>
                <p>
                    Using an outsourced solution like the&nbsp;<em>7G Registry Platform</em>, all technical issues are immediately solved. All administrators need is a modern internet browser and connection. No top-heavy investments are required since
                    the&nbsp;
                    <em>7G Registry Platform&nbsp;</em>is a&nbsp;<em>Software as a Service (SAAS)</em>&nbsp;model that works on a monthly subscription basis.<br></p>
                <h4><strong>Share Registry Providers (listed products)</strong></h4>
                <p>Share Registry Provider type clients are typically one of these. A company that provides share registry services as an outsourcing partner to listed companies or trusts. Or they can be a CoSec services company that wants to offer registry
                    services to their CoSec clients. Or, they can be the listed company itself that does its' own share registry. </p>
                <p>Developing a registry platform is a large undertaking in any circumstance, but to also automate all CHESS traffic that occurs for listed companies makes such a development project very cost prohibitive. Many listed companies simply assume
                    that they need to go to the big players like Computershare, but with the <strong><em>7G Registry Platform</em></strong> these types of services can be privided by any company without having to invest into any software development.</p>
                <p>&nbsp;</p>
                <hr>
                <p>&nbsp;</p>
                <h2><strong>Online Applications</strong></h2>
                <p><strong><em>7G Online Applications</em>&nbsp;</strong>is a module of the 7G platform that allows investors to enter their applications into the system, rather than sending in paper-based forms. The result leads to immediate great time-savings
                    for investors, advisers, fund managers/administrators alike.
                </p>
                <p>
                    A modern web-application allows the investor to create a user account on the system. Using that account, they can apply for units in any investment product under your management. The online processing helps the investor fill all this out, including identity
                    checks for Know-Your-Client (KYC) and AML/CTF compliance.
                </p>
                <p>
                    This will also allow the investor to have continued access to its data to update its’ details or request statements rather than contacting the registry provider. This also allows the investor to reuse its’ investment entity details to top-up investments
                    or invest in other investment products in your portfolio, without having to re-enter all their details.
                </p>
                <div style="height:20px"></div>
                <figure><img src="../../assets/img/OnlineApp-768x444.png" class="wp-image-547" srcset="../../assets/img/OnlineApp-768x444.png 1024w, ../../assets/img/OnlineApp-300x173.png 300w, ../../assets/img/OnlineApp-768x444.png 768w" sizes="(max-width: 1024px) 100vw, 1024px"></figure>
                <p>&nbsp;</p>
                <hr>
                <p>&nbsp;</p>
                <h2><strong>Online Investor Portal</strong></h2>
                <div style="height:20px"></div>
                <p><strong><em>The 7G Online Investor Portal</em></strong> is a module of the 7G platform that allows investors access to their investment accounts on 7G.&nbsp;</p>
                <div>
                    <figure><img src="../../assets/img/InvestorPortal_AccountB-768x445.png" srcset="../../assets/img/InvestorPortal_AccountB-768x445.png 1024w, ../../assets/img/InvestorPortal_AccountB-300x174.png 300w, ../../assets/img/InvestorPortal_AccountB-768x445.png 768w"
                            sizes="(max-width: 1024px) 100vw, 1024px"></figure>
                </div>
                <div style="height:20px"></div>
                <p>A modern web-application allows the investor to create a user account on the system. Using that account, they can see and update their details, see their transaction history and all their statements.</p>
                <p><strong>The result is the convenience to the investor while saving much work for the registry provider.</strong></p>
            </div>
            <aside class="col-md-3">
                <aside>
                    <div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/ZenBook-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/ZenBook-1-300x180.png 300w, ../../assets/img/ZenBook-1.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownRight animated"><img width="300" height="152" src="../../assets/img/Ipad-Pro-2-300x152.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Ipad-Pro-2-300x152.png 300w, ../../assets/img/Ipad-Pro-2-300x152.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/Pixel-4XL-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Pixel-4XL-1-300x180.png 300w, ../../assets/img/Pixel-4XL-1.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownRight animated"><img width="300" height="180" src="../../assets/img/PixelBook-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/PixelBook-300x180.png 300w, ../../assets/img/PixelBook.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/Mac-Pro-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Mac-Pro-1-300x180.png 300w, ../../assets/img/Mac-Pro-1.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownRight animated"><img width="300" height="180" src="../../assets/img/ZenBook-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/ZenBook-1-300x180.png 300w, ../../assets/img/ZenBook-1.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownLeft animated"><img width="300" height="152" src="../../assets/img/Ipad-Pro-2-300x152.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Ipad-Pro-2-300x152.png 300w, ../../assets/img/Ipad-Pro-2-300x152.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownRight animated"><img width="300" height="180" src="../../assets/img/Pixel-4XL-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Pixel-4XL-1-300x180.png 300w, ../../assets/img/Pixel-4XL-1.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/PixelBook-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/PixelBook-300x180.png 300w, ../../assets/img/PixelBook.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownRight animated"><img width="300" height="180" src="../../assets/img/Mac-Pro-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Mac-Pro-1-300x180.png 300w, ../../assets/img/Mac-Pro-1.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/ZenBook-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/ZenBook-1-300x180.png 300w, ../../assets/img/ZenBook-1.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownRight animated"><img width="300" height="152" src="../../assets/img/Ipad-Pro-2-300x152.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Ipad-Pro-2-300x152.png 300w, ../../assets/img/Ipad-Pro-2-300x152.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/Pixel-4XL-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Pixel-4XL-1-300x180.png 300w, ../../assets/img/Pixel-4XL-1.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownRight animated"><img width="300" height="180" src="../../assets/img/PixelBook-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/PixelBook-300x180.png 300w, ../../assets/img/PixelBook.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/Mac-Pro-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Mac-Pro-1-300x180.png 300w, ../../assets/img/Mac-Pro-1.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownRight animated"><img width="300" height="152" src="../../assets/img/Ipad-Pro-2-300x152.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Ipad-Pro-2-300x152.png 300w, ../../assets/img/Ipad-Pro-2-300x152.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownLeft animated"><img width="300" height="180" src="../../assets/img/Pixel-4XL-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Pixel-4XL-1-300x180.png 300w, ../../assets/img/Pixel-4XL-1.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownRight animated"><img width="300" height="180" src="../../assets/img/ZenBook-1-300x180.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/ZenBook-1-300x180.png 300w, ../../assets/img/ZenBook-1.png 499w" sizes="(max-width: 300px) 100vw, 300px"></div>
                        <div class="rotateInDownLeft animated"><img width="300" height="152" src="../../assets/img/Ipad-Pro-2-300x152.png" style="max-width: 100%; height: auto; margin-bottom: 30px;" srcset="../../assets/img/Ipad-Pro-2-300x152.png 300w, ../../assets/img/Ipad-Pro-2-300x152.png 499w"
                                sizes="(max-width: 300px) 100vw, 300px"></div>
                    </div>
                </aside>
            </aside>
        </div>
    </div>