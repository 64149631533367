<div class="slider_area">
    <div class="single_slider  d-flex align-items-center slider_bg_contact">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-xl-8">
                    <div class="slider_text text-center justify-content-center">
                        <h3>Contact Us</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<section class="contact-section">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <h2>We’d love to hear from you…</h2>
                <p>Get in touch to find out how we can customise solutions to help your company run more efficiently. We are ready to answer any questions you may have and look forward to hearing from you! </p>
                <div style="height:20px"></div>
                <div>
                    <figure><img src="../../assets/img/Ipad-Pro-2-300x152.png" width="379" height="192" srcset="../../assets/img/Ipad-Pro-2-300x152.png 499w, ../../assets/img/Ipad-Pro-1-300x152.png 300w" sizes="(max-width: 379px) 100vw, 379px"></figure>
                </div>
                <p>Use our contact form to submit a general enquiry and we will endeavour to respond within 24 hours. If you have a query of a specific nature you can connect directly with our sales or support team via email or phone shown above.
                </p>
                <p>Please ensure you fill out all fields before submitting.</p>
                <form method="post" id="arcf-contact-form" [formGroup]="contactForm" action="">
                    <fieldset><label for="arcf-name">Name <span style="color: red;">*</span></label><input type="text" class="form-control" id="arcf-name" name="arcf-name" required></fieldset>
                    <fieldset><label for="arcf-email">E-Mail <span style="color: red;">*</span></label><input type="email" class="form-control" id="arcf-email" name="arcf-email" required></fieldset>
                    <fieldset><label for="arcf-message">Your Message <span style="color: red;">*</span></label><textarea class="form-control" style="height: 200px;" id="arcf-message" name="arcf-message" required></textarea></fieldset>
                    <br>
                    <fieldset>
                        <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (expire)="handleExpire()" (load)="handleLoad()" (success)="handleSuccess($event)" formControlName="recaptcha">
                        </ngx-recaptcha2>
                    </fieldset>
                    <br>
                    <div class="require-fields"></div>
                    <fieldset><button onclick="sendMail(document.getElementById('arcf-name').value, document.getElementById('arcf-email').value, document.getElementById('arcf-message').value);" type="submit" class="btn btn-primary" name="arcf-submitted" id="arcf-submitted"
                            [disabled]="isValid">Submit</button></fieldset>
                </form>
            </div>
            <div class="col-md-3 offset-lg-1"><br>
                <div class="media contact-info"><span class="contact-info__icon"><i class="ti-user"></i></span>
                    <div class="media-body">
                        <h3>Get in touch</h3>
                        <div class="media_location">
                            <div class="location_contact">
                                <ul>
                                    <li><a href="https://www.linkedin.com/company/seveng-pty-ltd/" target="blank"><i
                      class="fa fa-linkedin"></i></a></li>
                                    <li><a href="https://web.whatsapp.com/adamwright" target="blank"><i class="fa fa-whatsapp"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <p>Chat to us on LinkedIn and WhatsApp</p>
                        </div>
                    </div>
                </div>
                <div class="media contact-info"><span class="contact-info__icon"><i class="ti-tablet"></i></span>
                    <div class="media-body">
                        <h3>+61 422 091 443</h3>
                        <p>Mon - Fri | 9am - 8pm</p>
                    </div>
                </div>
                <div class="media contact-info"><span class="contact-info__icon"><i class="ti-email"></i></span>
                    <div class="media-body">
                        <h3>sales@7g.com.au</h3>
                        <p>Send us your query anytime!</p>
                    </div>
                </div>
                <div class="media contact-info"><span class="contact-info__icon"><i class="ti-email"></i></span>
                    <div class="media-body">
                        <h3>support@7g.com.au</h3>
                        <p>Send us your query anytime!</p>
                    </div>
                </div>
                <div class="media contact-info"><span class="contact-info__icon"><i class="ti-home"></i></span>
                    <div class="media-body">
                        <h3>Mailing Adress</h3>
                        <p>SevenG<br>GPO Box 1548<br>Brisbane QLD 4001</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>