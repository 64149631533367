<div class="slider_area">
  <div class="single_slider  d-flex align-items-center slider_bg_testimonials">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-xl-8">
          <div class="slider_text text-center justify-content-center">
            <h3>Testimonials</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<hr>
<div class="container">
  <div class="row">
    <div class="col-md-9">
      <h2>
        <strong>
          Diversified Income
          Fund
        </strong>
      </h2>
      <p>
        <em>
          “We have been using the
          registry software provided by SevenG for around 4
          years. During this time, we have been impressed by the ease in which we
          have been able to conduct our two managed investment schemes that we
          have operated. One of our schemes was closed after the successful sale
          of assets earlier in 2008 and the SevenG software made the payment of
          redemptions, profit and final income a very simple task. We find the
          software easy to use, very functional and found that it has reduced the
          overall costs of running the managed investment scheme. We are happy to
          recommend SevenG&nbsp; for those organisations looking for a cost-effective
          registry solution.”
          <br>
        </em>
        <a href="http://www.templetons.com.au">www.templetons.com.au</a>
      </p>
      <h2>
        <strong>
          Lifesettlements
          Fund
        </strong>
      </h2>
      <p>
        <em>
          “Lifesettlements
          is a world-wide investment fund with more than $1b under
        </em>
        <em>

          management. Our backend and administration systems are of
          vital importance
          for our business. SevenG has been a provider to us for over 15
          years and their
          technologies give us a great advantage for client and
          channel
          communications. Audits, compliance and other mandatory
          issues are integrated
          and enables us to focus on the business and SevenG is a great
          business tool for
          us. Anybody in funds management should have a close look at
          SevenG and we are
          convinced that they will see this as a cheaper and more
          flexible tool than
          other solutions on the market today.”
          <br>
        </em>
        <a href="http://www.lifesettlementsfund.com">www.lifesttlementsfund.com</a>
      </p>
      <h2>
        <strong>
          Astrum
          Fund
        </strong>
      </h2>
      <p>
        <em>
          “When
          we commenced our funds management business in June 2005, we were looking for a
          registry
        </em>
        <em>&nbsp;</em>
        <em>

          system that provided a comprehensive solution but at a cost
          effective price. SevenG was able to provide
          this for us and we have been very happy with the
          functionality of the system from day one.
          As well as providing all the functions and reports, the
          system presents in a way that makes
          it look like our own. Communication with our investors is
          very important to us and SevenG&nbsp;
          enables them to log in over the internet and view the
          performance of their investment at
          any time. We are happy to recommend SevenG for those
          organisations looking for a cost&nbsp;
          effective registry and funds management solution.”
        </em>
      </p>
    </div>
    <aside class="col-md-3">
      <aside>
        <div>
          <div class="rotateInDownLeft animated">
            <img width="300" height="180" src="../../assets/img/ZenBook-1-300x180.png"
              style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/ZenBook-1-300x180.png 300w, ../../assets/img/ZenBook-1.png 499w"
              sizes="(max-width: 300px) 100vw, 300px">
          </div>
          <div class="rotateInDownRight animated">
            <img width="300" height="152" src="../../assets/img/Ipad-Pro-2-300x152.png"
              style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/Ipad-Pro-2-300x152.png 300w, ../../assets/img/Ipad-Pro-2-300x152.png 499w"
              sizes="(max-width: 300px) 100vw, 300px">
          </div>
          <div class="rotateInDownLeft animated">
            <img width="300" height="180" src="../../assets/img/Pixel-4XL-1-300x180.png"
              style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/Pixel-4XL-1-300x180.png 300w, ../../assets/img/Pixel-4XL-1.png 499w"
              sizes="(max-width: 300px) 100vw, 300px">
          </div>
          <div class="rotateInDownRight animated">
            <img width="300" height="180" src="../../assets/img/PixelBook-300x180.png"
              style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/PixelBook-300x180.png 300w, ../../assets/img/PixelBook.png 499w"
              sizes="(max-width: 300px) 100vw, 300px">
          </div>
          <div class="rotateInDownLeft animated">
            <img width="300" height="180" src="../../assets/img/Mac-Pro-1-300x180.png"
              style="max-width: 100%; height: auto; margin-bottom: 30px;"
              srcset="../../assets/img/Mac-Pro-1-300x180.png 300w, ../../assets/img/Mac-Pro-1.png 499w"
              sizes="(max-width: 300px) 100vw, 300px">
          </div>
        </div>
      </aside>
    </aside>
  </div>
</div>
